<template>
<div class="kt-form kt-form--label-right">
    <div class="kt-portlet__body">
        <div class="kt-section kt-section--first">
            <h3 class="kt-section__title kt-section__title-sm w-100">
                Billing Info:
            </h3>
            <div class="kt-section__body">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Purchase Order Number:</label>
                    <div class="col-lg-6">
                        <input
                            v-model="purchaseOrderNumber"
                            type="text"
                            class="form-control"
                            :class="{ 'is-valid': saved, 'is-invalid': invalidPO }"
                            :placeholder="savedPurchaseOrderNumber"
                        >
                        <div
                            v-if="saved"
                            class="valid-feedback"
                        >
                            Purchase order number saved!
                        </div>
                        <div
                            v-if="invalidPO"
                            class="invalid-feedback"
                        >
                            Order number should start with "WO" followed by a number, ex: WO230004240
                        </div>
                        <span class="form-text text-muted">Please enter your purchase order number</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Certified Purchase Order</label>
                    <div class="col-lg-6">
                        <div
                            v-if="!certifiedPurchaseOrder"
                            class="dropzone dropzone-default dz-clickable dropzone-primary"
                            @dragover.prevent
                            @drop="uploaderDrop"
                            @click="uploaderClick"
                        >
                            <div class="dropzone-msg dz-message needsclick">
                                <template>
                                    <h3 class="dropzone-msg-title">
                                        Drop Purchase Order here or click to upload.
                                    </h3>
                                    <form>
                                        <input
                                            ref="file"
                                            type="file"
                                            name="file"
                                            accept=".pdf"
                                            @change="onChange"
                                        >
                                    </form>
                                </template>
                            </div>
                        </div>
                        <div v-else>
                            <button
                                class="btn btn-success"
                                type="button"
                                :class="{ 'disable-events': true }"
                            >
                                <i class="fa fa-check" />
                                Certified Purchase Order
                            </button>
                            <button
                                class="btn btn-outline"
                                @click.stop.prevent="removeCertifiedPurchaseOrder"
                            >
                                <i class="fa fa-trash color-red" />
                            </button>
                        </div>
                        <div
                            v-if="invalidCertifiedPO"
                            class="text-danger"
                        >
                            Please upload a pdf file
                        </div>
                        <button
                            class="btn btn-primary mt-2 pull-right"
                            type="button"
                            :disabled="canSubmit"
                            @click.stop.prevent="savePurchaseOrderNumber"
                        >
                            Submit
                        </button>
                    </div>
                </div>
                <h3 class="kt-section__title kt-section__title-sm w-100">
                    Invoices:
                </h3>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>School Year</th>
                                <th>Purchase Order Number</th>
                                <th>Invoice</th>
                                <th>Certified PO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="subscription in subscriptions"
                                :key="subscription.id"
                            >
                                <td>{{ subscription.schoolYear }}</td>
                                <td>{{ subscription.purchaseOrderNumber || 'none' }}</td>
                                <td>
                                    <router-link
                                        v-if="subscription.invoice != 'Generated' && isSuperAdmin"
                                        :to="{ name: 'SubscriptionInvoice', params: { externalSchoolId: subscription.externalSchoolId, schoolYear: subscription.schoolYear } }"
                                    >
                                        Create
                                    </router-link>
                                    <router-link
                                        v-if="subscription.invoice === 'Generated'"
                                        :to="{ name: 'SubscriptionInvoice', params: { externalSchoolId: subscription.externalSchoolId, schoolYear: subscription.schoolYear } }"
                                    >
                                        Generated
                                    </router-link>
                                </td>
                                <td>{{ subscription.certifiedPO || 'none' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import Types from '../../store/Types';
import * as dbNetwork from '../../network';
import network from '../../lib/network';
import fileUtilities from '../../lib/fileUtilities';

export default {
    name: 'Billing',
    data() {
        return {
            savedPurchaseOrderNumber: null,
            savedCertifiedPO: null,
            purchaseOrderNumber: null,
            certifiedPurchaseOrder: null,
            saved: false,
            invalidPO: false,
            invalidCertifiedPO: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            userPermissions: (state) => state.user.userPermissions,
            schools: (state) => state.database.schools,
        }),
        canAccessBilling() {
            return this.isSuperAdmin || this.userPermissions.canManagePurchaseOrders || this.isSchoolAdmin;
        },
        valid() {
            const regex = /(WO)+\d*$/;
            return regex.test(this.purchaseOrderNumber);
        },
        isSchoolAdmin() {
            return this.user.school.role === 'School Admin';
        },
        canSubmit() {
            return !this.certifiedPurchaseOrder || (this.certifiedPurchaseOrder == this.savedCertifiedPO && !this.purchaseOrderNumber);
        },
        subscriptions() {
            return this.schools[0] && this.schools[0].subscriptions;
        },
    },
    mounted() {
        const v = this;
        if (!v.canAccessBilling) v.$router.push({ name: 'MyProfile' });
        const params = {
            url: { schoolId: v.user.school.schoolId, schoolTermId: v.user.school.schoolTermId },
        };
        dbNetwork.schools.getSubscription(params, (err, data) => {
            this.savedPurchaseOrderNumber = data.subscription.purchaseOrderNumber;
            this.savedCertifiedPO = data.subscription.certifiedPurchaseOrder;
            this.certifiedPurchaseOrder = data.subscription.certifiedPurchaseOrder;
        });
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }
    },
    methods: {
        uploaderClick() {
            const elem = this.$refs.file;
            elem.click();
        },
        uploaderDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            if (e.dataTransfer.files[0].type !== 'application/pdf') this.invalidCertifiedPO = true;
            else {
                const file = e.dataTransfer.files[0];
                this.certifiedPurchaseOrder = file;
                this.invalidCertifiedPO = false;
            }
        },
        onChange(e) {
            if (e.target.files[0].type !== 'application/pdf') this.invalidCertifiedPO = true;
            else {
                const file = e.target.files[0];
                this.certifiedPurchaseOrder = file;
                this.invalidCertifiedPO = false;
            }
        },
        removeCertifiedPurchaseOrder() {
            this.certifiedPurchaseOrder = null;
        },
        savePurchaseOrderNumber() {
            const v = this;
            if (!v.valid) {
                v.invalidPO = true;
                v.saved = false;
                return;
            }
            if (!v.certifiedPurchaseOrder) {
                v.invalidCertifiedPO = true;
                v.saved = false;
                return;
            }
            async.auto(
                {
                    parameters(next) {
                        if (v.savedCertifiedPO == v.certifiedPurchaseOrder) return next();
                        const status = v.certifiedPurchaseOrder;
                        if (status.error) return next();
                        status.status = 'Preparing';
                        status.fileType = { name: 'certifiedPO', extension: 'pdf' };
                        const fileDetails = {
                            internalName: status.fileType.name,
                            fileExtension: status.fileType.extension,
                        };
                        network.upload.getUploadParameters(v.user.school, fileDetails, (err, res) => {
                            status.error = err;
                            next(null, res);
                        });
                    },
                    aws: [
                        'parameters',
                        function (results, next) {
                            if (v.savedCertifiedPO == v.certifiedPurchaseOrder) return next();
                            const file = v.certifiedPurchaseOrder;
                            if (file.error) return next();
                            fileUtilities.awsUpload(results.parameters, file, file, next);
                        },
                    ],
                    database: [
                        'aws',
                        function (results, next) {
                            const file = v.certifiedPurchaseOrder;
                            if (file.error) return next();

                            const params = {
                                url: { schoolId: v.user.school.schoolId, schoolTermId: v.user.school.schoolTermId },
                                body: {
                                    purchaseOrderNumber: v.purchaseOrderNumber,
                                    certifiedPurchaseOrder: v.savedCertifiedPO == v.certifiedPurchaseOrder ? v.savedCertifiedPO : `https://s3.amazonaws.com/lilo-app/${file.bucketPath}`,
                                },
                            };
                            dbNetwork.schools.updatePurchaseOrder(params, (err) => {
                                if (err) return v.showError(err);
                                v.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
                                v.saved = true;
                                v.invalidPO = false;
                                v.savedPurchaseOrderNumber = v.purchaseOrderNumber;
                                v.purchaseOrderNumber = null;
                                next();
                            });
                        },
                    ],
                },
                () => {
                    const file = v.certifiedPurchaseOrder;
                    if (!file.error) {
                        v.showError(file.error);
                    }
                },
            );
        },
    },
};
</script>

<style scoped>
input[type='file'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.color-red {
    color: red;
}

.disable-events {
    pointer-events: none;
}
</style>
